/* src/styles/questionnaire.css */
body {
    background-color: #0e0e10;
    color: #ffffff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .questionnaire-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #181818;
  }
  
  .question-description {
    font-family: 'Arial', sans-serif;
    font-size: 1.1rem; /* Increased font size */
    color: #ff69b4; /* Slightly softer white for a professional look */
    background-color: #2a2a2a; /* Slightly different background for contrast */
    border-left: 4px solid #ff69b4; /* A touch of color to signify importance */
    padding: 15px; /* Add padding for a better visual grouping */
    margin-bottom: 30px; /* Additional space below the description */
    border-radius: 5px; /* Slight rounding of corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  }
  
  .question {
    margin-bottom: 25px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
  }
  
  .circle-rating-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    background-color: #555;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .circle.selected {
    background-color: #ff69b4; /* Selected circle color */
  }
  
  .circle:hover {
    background-color: #666; /* Hover circle color */
  }
  

  /* src/styles/questionnaire.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: #252525;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modal h2 {
  color: #ff69b4;
  margin-bottom: 10px;
}

.score-result {
  font-size: 3em;
  color: #4caf50;
  margin: 20px 0;
}

.modal p {
  color: #ddd;
  margin-bottom: 20px;
}

.modal button {
    background-color: #ff69b4; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button {
    background-color: #ff69b4; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }

  .submit-instruction {
    color: #ff69b4; /* Pink text for the instruction */
    text-align: right;
    margin-right: 10px; /* Or as needed */
    font-style: italic;
  }
  
  button:hover {
    background-color: #ff85c0; /* Lighter pink on hover */
    transform: translateY(-2px); /* Slight lift to give a button a 'pop' effect */
  }
  .result {
    margin-top: 20px;
    padding: 20px;
    background-color: #333;
    border-radius: 5px;
    color: #ff69b4;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

.binary-choice-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  
  .binary-option {
    background-color: #555; /* Neutral background */
    border: none;
    color: white;
    padding: 10px 10px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .binary-option.selected {
    background-color: #ff69b4; /* Pink background for selected option */
  }
  
  .binary-option:not(.selected):hover {
    background-color: #777; /* Slightly lighter background on hover for non-selected */
  }
  
  /* ... remaining styles ... */
  